import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { css } from '@emotion/core';
import { Fab } from '@material-ui/core';
import styles from '../../styles';
import Icon from '../../components/Icon';

const messages = defineMessages({
  sidebarMenu: {
    id: 'sidebar.menu',
    defaultMessage: 'Sidebar Menu'
  }
});

const MobileSidebarNav = ({ isOpen, onClick, intl, children }) => (
  <>
    <div css={style.root(isOpen)}>
      <div id='js-sidebar-nav' css={style.sidebarWrap(isOpen)}>
        <div css={style.sidebar}>{children}</div>
      </div>
    </div>
    <div css={style.button}>
      <Fab
        color='primary'
        onClick={onClick}
        aria-controls='js-sidebar-nav'
        aria-label={intl.formatMessage(messages.sidebarMenu)}
        aria-expanded={isOpen}>
        <Icon style={[style.navIcon, style.navIconTop(isOpen)]} name='arrow' />
        <Icon style={[style.navIcon, style.navIconBottom(isOpen)]} name='arrow' />
      </Fab>
    </div>
  </>
);

MobileSidebarNav.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
};

const style = {
  root: isOpen => css`
    pointer-events: ${isOpen ? 'auto' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    @media (min-width: ${styles.breakpoint.md}) {
      display: none;
    }
  `,
  sidebarWrap: isOpen => css`
    visibility: ${isOpen ? 'visible' : 'hidden'};
    opacity: ${isOpen ? '1' : '0'};
    position: relative;
    background-color: ${styles.color.white};
    transition: opacity ${styles.easing.medTime} ${styles.easing.authenticMotion},
      visibility ${styles.easing.medTime} ${styles.easing.authenticMotion};
    /* Add padding top for fixed header */
    top: 6.159rem;
    @media (min-width: ${styles.breakpoint.xs}) {
      top: 6.8rem;
    }
    @media (min-width: ${styles.breakpoint.sm}) {
      top: 7.119rem;
    }
  `,
  sidebar: css`
    padding: ${styles.spacing.grid} ${styles.spacing.grid} ${styles.spacing.xxl};
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
  `,
  button: css`
    position: fixed;
    bottom: ${styles.spacing.sm};
    right: ${styles.spacing.sm};
    z-index: 2;
    @media (min-width: ${styles.breakpoint.md}) {
      display: none;
    }
  `,
  navIcon: css`
    font-size: 0.28rem;
    position: absolute;
    left: 50%;
    transition: top ${styles.easing.time} ${styles.easing.easeInOutBack};
  `,
  navIconTop: isOpen => css`
    top: ${isOpen ? 'calc(50% - 0.28rem)' : 'calc(50% + 0.7rem)'};
    transform: translate(-50%, -50%);
  `,
  navIconBottom: isOpen => css`
    top: ${isOpen ? 'calc(50% + 0.28rem)' : 'calc(50% - 0.7rem)'};
    transform: translate(-50%, -50%) rotate(180deg);
  `
};

export default injectIntl(MobileSidebarNav);
