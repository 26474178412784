import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../../../styles';
import SidebarAccordionSection from './SidebarAccordionSection';

class SidebarAccordion extends Component {
  constructor(props) {
    super(props);
    const { initialCategory } = props;
    const openSections = initialCategory ? { [initialCategory]: true } : {};
    this.state = { openSections };
  }

  render() {
    const { style, section, categories, onItemClick } = this.props;
    const { openSections } = this.state;
    return (
      <nav css={style}>
        {categories.map(category => (
          <SidebarAccordionSection
            key={category.slug}
            style={ownStyle}
            section={section}
            category={category}
            isOpen={!!openSections[category.slug]}
            onClick={this._handleClick}
            onItemClick={onItemClick}
          />
        ))}
      </nav>
    );
  }

  _handleClick = id => {
    const { openSections } = this.state;
    const isOpen = !!openSections[id];
    this.setState({ openSections: { [id]: !isOpen } });
  };
}

SidebarAccordion.propTypes = {
  section: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  initialCategory: PropTypes.string,
  onItemClick: PropTypes.func
};

const ownStyle = css`
  margin-bottom: ${styles.spacing.xs};
  &:last-child {
    margin-bottom: 0;
  }
`;

export default SidebarAccordion;
