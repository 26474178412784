import styled from '@emotion/styled';
import styles from '../../styles';

const Card = styled.div`
  box-shadow: ${styles.shadow.default};
  border-radius: ${styles.border.radius};
  background: ${styles.color.white};
`;

export default Card;
