import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../../../styles';
import Icon from '../../../../components/Icon';
import LocalizedLink from '../../../../components/locale/LocalizedLink';

const Breadcrumb = ({ className, style, to, children }) => (
  <LocalizedLink className={className} css={[ownStyle.root, style]} to={to}>
    <Icon style={ownStyle.icon} name='arrow' />
    {children}
  </LocalizedLink>
);

Breadcrumb.propTypes = {
  to: PropTypes.string.isRequired
};

const ownStyle = {
  root: css`
    color: ${styles.color.black};
    font-weight: ${styles.font.weight.medium};
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: color ${styles.easing.time} ${styles.easing.main};
    &:hover {
      color: ${styles.color.purple};
    }
  `,
  icon: css`
    font-size: 0.34rem;
    transform: rotate(90deg);
    margin-right: ${styles.spacing.tiny};
    margin-left: -0.34rem;
  `
};

export default Breadcrumb;
