import React from 'react';
import { shuffle } from 'lodash';
import { graphql } from 'gatsby';
import BaseLayout from '../components/layout/BaseLayout';
import SEO from '../components/SEO';
import MarkdownTemplate from './MarkdownTemplate';
import { MoreArticles } from '../components/layout/MoreArticles';

const DocsTemplate = ({
  data,
  pageContext: { locale, section, category, fileAbsolutePath },
  location,
}) => {
  // Navigation items
  const navItems = data.allYaml.edges[0].node.nav.categories;

  // Current post
  const {
    html,
    frontmatter: { title },
  } = data.allMarkdownRemark.edges.find(
    (edge) => edge.node.fileAbsolutePath === fileAbsolutePath
  ).node;

  // Poor man's related posts algorithm
  const relatedItems = shuffle(
    data.allMarkdownRemark.edges
      .filter(
        (edge) =>
          edge.node.fileAbsolutePath.includes(locale) &&
          edge.node.fileAbsolutePath.includes(section) &&
          !edge.node.fileAbsolutePath.includes(fileAbsolutePath)
      )
      .map((edge) => ({
        link: `/${section}/${edge.node.fileAbsolutePath
          .split('/')
          .slice(-1)[0]
          .split('.')[0]
          .replace('home', '')}`.replace(/\/$/, ''),
        heading: edge.node.frontmatter.title,
        text: edge.node.frontmatter.excerpt,
      }))
  ).splice(0, 3);

  return (
    <BaseLayout
      locale={locale}
      render={() => (
        <>
          <SEO title={title} locale={locale} pathname={location.pathname} />
          <MarkdownTemplate
            section={section}
            sidebarCategories={navItems}
            initialSidebarCategory={category}
            html={html}
          />
        </>
      )}
      renderRelated={() => <MoreArticles items={relatedItems} />}
    />
  );
};

export const query = graphql`
  query($section: String!) {
    allMarkdownRemark {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
            excerpt
          }
          html
        }
      }
    }
    allYaml(filter: { nav: { section: { eq: $section } } }) {
      edges {
        node {
          nav {
            categories {
              slug
              translationKey
              items {
                slug
                translationKey
                items {
                  slug
                  translationKey
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default DocsTemplate;
