import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import Card from '../../Card';
import LocalizedLink from '../../locale/LocalizedLink';

const messages = defineMessages({
  heading: {
    id: 'moreArticles.heading',
    defaultMessage: 'Articles in This Section',
  },
});

export const MoreArticles = ({ items }) => {
  const intl = useIntl();

  return (
    <div css={classes.root} className='h-pt-lg h-pb-lg'>
      <h3 className='h3' css={classes.heading}>
        {intl.formatMessage(messages.heading)}
      </h3>
      <div className='container'>
        <div className='row'>
          {items.map((item) => (
            <div key={item.link} className='col-sm-4' css={classes.cardWrap}>
              <LocalizedLink css={classes.link} to={item.link}>
                <Card css={classes.card}>
                  <h4 className='h4'>{item.heading}</h4>
                  <p css={classes.text} className='p'>
                    {item.text}
                  </p>
                </Card>
              </LocalizedLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

MoreArticles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const classes = {
  root: css`
    background-color: ${styles.color.offWhite};
    @media (min-width: ${styles.breakpoint.mobileNav}) {
      margin-top: -8rem;
      margin-bottom: 8rem;
    }
  `,
  link: css`
    color: ${styles.color.black};
    text-decoration: none;
  `,
  heading: css`
    text-align: center;
    max-width: 32rem;
    margin: 0 auto ${styles.spacing.sm};
    @media (min-width: ${styles.breakpoint.sm}) {
      max-width: 48rem;
      margin: 0 auto ${styles.spacing.md};
    }
  `,
  text: css`
    color: ${styles.color.grey};
  `,
  cardWrap: css`
    margin-bottom: 3rem;
  `,
  card: css`
    height: 100%;
    padding: 3rem;
    max-width: 40rem;
    margin: 0 auto;
  `,
};
