import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../../../../styles';
import Icon from '../../../../../components/Icon';
import LocalizedLink from '../../../../../components/locale/LocalizedLink';
import { useIntl } from 'react-intl';

const getDocsPathBySlug = (section, slug) => {
  let path = `/${section}/${slug}`;
  if (slug === '/') {
    path = `/${section}`;
  } else if (slug[0] === '#') {
    path = `/${section}${slug}`;
  }
  return path;
};

const SidebarAccordionSection = ({
  style,
  section,
  category: { slug, translationKey, items },
  isOpen,
  onClick,
  onItemClick,
}) => {
  const intl = useIntl();
  return (
    <div css={style}>
      <button css={ownStyle.toggle(isOpen)} onClick={() => onClick(slug)}>
        {intl.formatMessage({ id: translationKey })}
        <Icon style={ownStyle.toggleIcon(isOpen)} name='arrow' />
      </button>
      <ul id={`sidebar-${slug}`} css={ownStyle.drawer(isOpen)}>
        {items.map((item) => (
          <li key={item.slug}>
            <LocalizedLink
              onClick={onItemClick}
              className='a'
              to={getDocsPathBySlug(section, item.slug)}
              activeClassName='is-active'>
              {intl.formatMessage({ id: item.translationKey })}
            </LocalizedLink>
            {item.items && item.items.length > 0 && (
              <ul>
                {item.items.map((subitem) => (
                  <li key={subitem.slug}>
                    <LocalizedLink
                      onClick={onItemClick}
                      className='a'
                      to={getDocsPathBySlug(section, subitem.slug)}
                      activeClassName='is-active'>
                      {intl.formatMessage({ id: subitem.translationKey })}
                    </LocalizedLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

SidebarAccordionSection.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  section: PropTypes.string.isRequired,
  category: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    translationKey: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        translationKey: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
            translationKey: PropTypes.string.isRequired,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  onItemClick: PropTypes.func,
};

const ownStyle = {
  toggle: (isOpen) => css`
    cursor: pointer;
    font-weight: ${styles.font.weight.medium};
    font-size: ${styles.font.size.p1};
    display: flex;
    align-items: center;
    color: ${isOpen ? styles.color.purple : styles.color.medLightGrey};
    transition: color ${styles.easing.time} ${styles.easing.main};
    line-height: 1.5;
    text-align: left;
    max-width: 21rem;
    &:hover {
      color: ${styles.color.purple};
    }
    @media (min-width: 1600px) {
      font-size: ${styles.font.size.p};
    }
    @media (min-width: 1960px) {
      max-width: none;
    }
  `,
  toggleIcon: (isOpen) => css`
    margin-left: ${styles.spacing.tiny};
    transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0)'};
    font-size: 0.2rem;
    @media (min-width: 1600px) {
      font-size: 0.22rem;
    }
  `,
  drawer: (isOpen) => css`
    display: ${isOpen ? 'block' : 'none'};
    padding-top: ${styles.spacing.xxs};
    li {
      margin-bottom: ${styles.spacing.tiny};
      &:last-child {
        margin-bottom: 0;
      }
      a {
        text-decoration: none;
        color: ${styles.color.black};
        font-size: ${styles.font.size.p1};
        @media (min-width: 1600px) {
          font-size: ${styles.font.size.p};
        }
        &.is-active {
          color: ${styles.color.purple};
        }
      }
      ul {
        margin-top: ${styles.spacing.tiny};
        margin-left: ${styles.spacing.xs};
      }
    }
  `,
};

export default SidebarAccordionSection;
