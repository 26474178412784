import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import styles from '../../styles';
import Sidebar from '../../components/layout/Sidebar';
import MobileSidebarNav from './MobileSidebarNav';

const MarkdownTemplate = ({ section, sidebarCategories, initialSidebarCategory, html }) => (
  <div className='container'>
    <div className='row'>
      <div className='col-md-4 col-lg-3'>
        <DesktopSidebar
          section={section}
          categories={sidebarCategories}
          initialCategory={initialSidebarCategory}
        />
        <MobileSidebar
          section={section}
          categories={sidebarCategories}
          initialCategory={initialSidebarCategory}
        />
      </div>
      <div className='col-md-8 col-lg-9' css={style.contentWrap}>
        <div className='s-post' css={style.content} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  </div>
);

MarkdownTemplate.propTypes = {
  section: PropTypes.string.isRequired,
  sidebarCategories: PropTypes.array.isRequired,
  initialSidebarCategory: PropTypes.string,
  html: PropTypes.string.isRequired,
};

const style = {
  contentWrap: css`
    background: ${styles.color.white};
    @media (min-width: ${styles.breakpoint.md}) {
      border-left: 1px solid ${styles.border.color.grey};
    }
  `,
  content: css`
    @media (min-width: ${styles.breakpoint.md}) {
      padding: ${styles.spacing.xs};
    }
  `,
};

const DesktopSidebar = ({ section, categories, initialCategory }) => (
  <div css={desktopSidebarStyle.root}>
    <div css={desktopSidebarStyle.sidebar}>
      <Sidebar section={section} categories={categories} initialCategory={initialCategory} />
    </div>
  </div>
);

const desktopSidebarStyle = {
  root: css`
    height: 100%;
    position: relative;
    display: none;
    @media (min-width: ${styles.breakpoint.md}) {
      display: block;
    }
  `,
  sidebar: css`
    padding: ${styles.spacing.md} 0 21rem;
    position: sticky;
    top: 7.2rem;
    overflow-y: auto;
  `,
};

const MobileSidebar = ({ section, categories, initialCategory }) => {
  const [isOpen, setIsOpen] = useState(false);

  const _handleClick = () => setIsOpen((state) => !state);

  const _handleItemClick = () => setIsOpen(false);

  return (
    <MobileSidebarNav isOpen={isOpen} onClick={_handleClick}>
      <Sidebar
        section={section}
        categories={categories}
        initialCategory={initialCategory}
        onItemClick={_handleItemClick}
      />
    </MobileSidebarNav>
  );
};

export default MarkdownTemplate;
