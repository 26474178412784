import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/core';
import styles from '../../../styles';
import Breadcrumb from './Breadcrumb';
import SidebarAccordion from './SidebarAccordion';

const Sidebar = ({ section, categories, initialCategory, onItemClick }) => (
  <div>
    <Breadcrumb className='h-mb-xs' style={ownStyle.breadcrumb} to='/'>
      <FormattedMessage id='sidebar.home' defaultMessage='Home' />
    </Breadcrumb>
    <SidebarAccordion
      style={ownStyle.accordion}
      section={section}
      categories={categories}
      initialCategory={initialCategory}
      onItemClick={onItemClick}
    />
  </div>
);

SidebarAccordion.propTypes = {
  section: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  initialCategory: PropTypes.string,
  onItemClick: PropTypes.func,
};

const ownStyle = {
  breadcrumb: css`
    font-size: ${styles.font.size.p1};
    @media (min-width: 1600px) {
      font-size: ${styles.font.size.p};
    }
  `,
  accordion: css`
    margin-left: 2.2rem;
    overflow-y: auto;
    max-height: calc(100vh - 20rem);
  `,
};

export default Sidebar;
